import React, { useEffect, useState } from "react";
import "./Home.scss";
import HomeLayout from "./HomeLayout";
import { ComponentHeader } from "../../Components/UI";
import { Link } from "@mui/material";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import Avatar from "@mui/material/Avatar";
import { Container, Grid } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import RegistrationSuccessModal from "../../Components/Modals/ModalContexts/RegistrationSuccessModal";
import { connect } from "react-redux";
import ForgetPasswordModal from "../../Components/Modals/ModalContexts/ForgetPasswordModal";
const backgroundImage = "images/homepage.webp";

const featureResponsive = {
  0: { items: 1 },
  1024: { items: 1 },
};
const desktopstagePadding = {
  paddingLeft: 300,
  paddingRight: 300,
};
const mobilestagePadding = {
  paddingLeft: 1,
  paddingRight: 1,
};

function Home({ user, ...props }) {
  const isMobile = window.innerWidth < 600;
  const [openSuccessModal, setSuccessModal] = useState(false);
  const [openForgetPwModal, toggleOpenForgetPwModal] = useState(false);
  // const { height, width } = useWindowDimensions();

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params?.rg === "1") {
      setSuccessModal(true);
    }
    if (params?.rg === "forget-password") {
      toggleOpenForgetPwModal(true);
    }
    // if (
    //   !!Object.keys(user?.imageUrl ?? {}).length &&
    //   !!user?.accessToken &&
    //   params?.rg !== "1"
    // ) {
    //   props.history?.push("/my-matches");
    // } else {
    // }
  }, []);

  return (
    <div
      id="home"
      className="LandingPage"
      style={{ backgroundColor: "#040404b3" }}
    >
      <HomeLayout
        sxBackground={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
        }}
      />
      <a
        href="https://wa.me/message/BN2R4ZFIMY4JM1"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      {/* <div id="about" style={{paddingTop: "4vh"}}></div> */}
      <Container
        id="about"
        className="base-container"
        maxWidth="unset"
        style={{ backgroundColor: "white", scrollMargin: "2em" }}
      >
        <Grid container={true} spacing={2}>
          <Grid item xs={12} md={8}>
            <Grid item>
              <ComponentHeader headingText="About us" borderWidth="10em" />
            </Grid>
            <Grid item>
              <Grid item>
                <Typography component="div" sx={{ px: 1, py: 1 }}>
                  VVS (Vadhu Vara Samyojana)is the brain child of His Holiness
                  1008 Sri Satyatma Tirtha Swamiji. It has been Swamiji’s
                  constant endeavor to establish a healthy & vibrant Madhwa
                  society and ensure all round development through various
                  social initiatives.
                  <br /> Marriage is considered as the corner stone of the Hindu
                  family system. In order to strengthen this system and build a
                  healthy society, His Holiness Sri Swamiji embarked on the
                  ambitious concept of Vadhu Vara Samyojana during October 2012.
                  <br /> VVS provides a seamless platform for brides, grooms and
                  their families to view, evaluate & select their prospective
                  life partners and establish a life-long matrimonial bond. VVS
                  is driven mainly through a state of the art online portal
                  which is supplemented through periodic on-ground events held
                  at various locations.
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  component="div"
                  fontSize={24}
                  sx={{ px: 1, py: 1, fontWeight: "bold" }}
                >
                  About JAYA SATYA PRAMODA NIDHI (JSPN)
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  component="div"
                  sx={{ px: 1, py: 1, lineHeight: "28px" }}
                >
                  JSPN, apart from VVS, is successfully involved in various
                  social initiatives Scholarships for the needy meritorious
                  Madhwa students.
                  <br /> Free medical camps. Job fairs. Career guidance
                  workshops and so on. For more information on JSPN, learning
                  and participating in its activities, visit{" "}
                  <Link
                    target="_blank"
                    href="https://www.srijspn.org"
                    rel="noreferrer"
                    underline="hover"
                  >
                    www.srijspn.org
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              className="about_us_image"
              src="images/about_us.webp"
              alt="about us "
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography fontSize={24} sx={{ px: 1, py: 1, fontWeight: "bold" }}>
              Video of V V S 2.0 launch by Sri Satyatma Teertha Swamiji on
              13-Nov-2022 at Coimbatore
            </Typography>
            <div position="relative" overflow="hidden" align="center">
              <iframe
                style={{
                  // maxWidth: 640,
                  minHeight: isMobile ? 280 : 480,
                  width: "100%",
                  height: "100%",
                  overflow: "auto",
                }}
                scrolling="no"
                allowFullScreen
                src="https://drive.google.com/file/d/1obmfPvs569hbxkaOsGHOSw5rtIkgaHIA/preview"
                width="80%"
              ></iframe>
            </div>
          </Grid>
        </Grid>
      </Container>
      {/* <div id="working" style={{paddingTop: "4vh"}}></div> */}
      <Container
        id="working"
        className="base-container"
        maxWidth="unset"
        style={{ backgroundColor: "#FFF8EE", scrollMargin: "2em" }}
      >
        <div>
          <ComponentHeader
            headingText="How Vadhu Vara Samyojana Works"
            borderWidth="14em"
          />
        </div>
        <Timeline position="right">
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                sx={{ py: 1.5, px: 3 }}
                style={{
                  backgroundColor: "#FFD9E6",
                  color: "black",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  component="span"
                  fontFamily="sans-serif"
                  fontWeight="900"
                >
                  1
                </Typography>
              </TimelineDot>
              <TimelineConnector
                style={{ backgroundColor: "#FFB955", minHeight: "4rem" }}
              />
              <div
                style={{
                  backgroundColor: "#FFB955",
                  padding: "8px",
                  transform: "rotate(45deg)",
                }}
              ></div>
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography
                variant="h6"
                component="span"
                color="#663399"
                fontWeight={900}
              >
                Register & Activate
              </Typography>
              <Typography>
                Verify your Email & Mobile Number and create Password.
                Registration is absolutely free.{" "}
              </Typography>
            </TimelineContent>
            <TimelineOppositeContent
              align="right"
              variant="body1"
              color="text.secondary"
            >
              <img src="/images/login_timeline.png" alt="timeline register" />
            </TimelineOppositeContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                sx={{ py: 1.5, px: 3 }}
                style={{
                  backgroundColor: "#FFD9E6",
                  color: "black",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  component="span"
                  fontFamily="sans-serif"
                  fontWeight="900"
                >
                  2
                </Typography>
              </TimelineDot>
              <TimelineConnector
                style={{ backgroundColor: "#FFB955", minHeight: "4rem" }}
              />
              <div
                style={{
                  backgroundColor: "#FFB955",
                  padding: "8px",
                  transform: "rotate(45deg)",
                }}
              ></div>
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography
                variant="h6"
                component="span"
                color="#663399"
                fontWeight={900}
              >
                Complete your profile
              </Typography>
              <Typography>
                Update the Education & Professional Details, Horoscope and
                Family details{" "}
              </Typography>
            </TimelineContent>
            <TimelineOppositeContent
              align="right"
              variant="body1"
              color="text.secondary"
            >
              <img src="/images/check_timeline.png" alt="timeline checkbox" />
            </TimelineOppositeContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                sx={{ py: 1.5, px: 3 }}
                style={{
                  backgroundColor: "#FFD9E6",
                  color: "black",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  component="span"
                  fontFamily="sans-serif"
                  fontWeight="900"
                >
                  3
                </Typography>
              </TimelineDot>
              <TimelineConnector
                style={{ backgroundColor: "#fFB955", minHeight: "4rem" }}
              />
              <div
                style={{
                  backgroundColor: "#FFB955",
                  padding: "8px",
                  transform: "rotate(45deg)",
                }}
              ></div>
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography
                variant="h6"
                component="span"
                color="#663399"
                fontWeight={900}
              >
                View Your Matches
              </Typography>
              <Typography>Filter your matches and shortlist </Typography>
            </TimelineContent>
            <TimelineOppositeContent
              align="right"
              variant="body1"
              color="text.secondary"
            >
              <img src="/images/jigsaw_timeline.webp" alt="timeline heart" />
            </TimelineOppositeContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                sx={{ py: 1.5, px: 3 }}
                style={{
                  backgroundColor: "#FFD9E6",
                  color: "black",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  component="span"
                  fontFamily="sans-serif"
                  fontWeight="900"
                >
                  4
                </Typography>
              </TimelineDot>
              <TimelineConnector
                style={{ backgroundColor: "#FFB955", minHeight: "4rem" }}
              />
              <div
                style={{
                  backgroundColor: "#FFB955",
                  padding: "8px",
                  transform: "rotate(45deg)",
                }}
              ></div>
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography
                variant="h6"
                component="span"
                color="#663399"
                fontWeight={900}
              >
                Send/Receive Invitation
              </Typography>
              <Typography>
                Send the invite on the Website to the shortlisted profiles{" "}
              </Typography>
            </TimelineContent>
            <TimelineOppositeContent
              align="right"
              variant="body1"
              color="text.secondary"
            >
              <img src="/images/email_timeline.png" alt="timeline email" />
            </TimelineOppositeContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                sx={{ py: 1.5, px: 3 }}
                style={{
                  backgroundColor: "#FFD9E6",
                  color: "black",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  component="span"
                  fontFamily="sans-serif"
                  fontWeight="900"
                >
                  5
                </Typography>
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography
                variant="h6"
                component="span"
                color="#663399"
                fontWeight={900}
              >
                Accept your proposal
              </Typography>
              <Typography>
                Contact details will be shared once the invite is accepted{" "}
              </Typography>
            </TimelineContent>
            <TimelineOppositeContent
              align="right"
              variant="body1"
              color="text.secondary"
            >
              <img
                src="/images/proposal_timeline.webp"
                alt="timeline proposal"
              />
            </TimelineOppositeContent>
          </TimelineItem>
        </Timeline>
      </Container>
      {/* <div id="benefits" style={{paddingTop: "4vh"}}></div> */}
      <Container
        id="benefits"
        className="base-container"
        maxWidth="unset"
        style={{ backgroundColor: "white", scrollMargin: "2em" }}
      >
        <div>
          <ComponentHeader headingText="Why VVS?" borderWidth="8em" />
        </div>

        <Grid container sx={{ py: 2 }}>
          <Grid
            container
            spacing={1}
            sm={12}
            xs={12}
            md={12}
            lg={6}
            xl={6}
            sx={{ py: 2 }}
          >
            <Grid xs item className="imgclass">
              <img alt="complex" src="/images/horoscope_benefits.webp" />
            </Grid>
            <Grid item xs sm md container spacing={1}>
              <Grid item xs container direction="column">
                <Grid item xs>
                  <Typography color="#663399" variant="h6" fontWeight={900}>
                    Verified Profiles
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    All profiles on the website are verified with Mobile Number
                    and Email. Profiles older than 180 days won't be listed and
                    Intelligent scripts run in the background to maintain clean
                    and healthy system.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            sm={12}
            xs={12}
            md={12}
            lg={6}
            xl={6}
            sx={{ py: 2 }}
          >
            <Grid xs item className="imgclass">
              <img alt="complex" src="/images/filter_benefits.png" />
            </Grid>
            <Grid item xs sm md container spacing={1}>
              <Grid item xs container direction="column">
                <Grid item xs>
                  <Typography color="#663399" variant="h6" fontWeight={900}>
                    Auto Matching of Horoscope
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {
                      "Matches are generated while considering Gotra (Separation of Gotras), Guna >= 18, Griha Maitri >= 2.5, Age gap of greater than 6 months (Vara atleast 6 months elder to Vadhu). Custom filters are also available."
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            sm={12}
            xs={12}
            md={12}
            lg={6}
            xl={6}
            sx={{ py: 2 }}
          >
            <Grid xs item className="imgclass">
              <img alt="complex" src="/images/group_benefits.webp" />
            </Grid>
            <Grid item xs sm md container spacing={1}>
              <Grid item xs container direction="column">
                <Grid item xs>
                  <Typography color="#663399" variant="h6" fontWeight={900}>
                    Data is Updated, Secured and Private
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Your Personal data like Contact No, Photo and other details
                    are Secure. It will not be shared with anyone outside our
                    JSPN Entity
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            sm={12}
            xs={12}
            md={12}
            lg={6}
            xl={6}
            sx={{ py: 2 }}
          >
            <Grid xs item className="imgclass">
              <img alt="complex" src="/images/free_cost.webp" />
            </Grid>
            <Grid item xs sm md container spacing={1}>
              <Grid item xs container direction="column">
                <Grid item xs>
                  <Typography color="#663399" variant="h6" fontWeight={900}>
                    Free of Cost
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    This is absolutely free service and the only purpose of this
                    website is to enable the Madhwa Community with a good
                    Matrimonial Service.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {/* <div id="success-story" style={{paddingTop: "4vh"}}></div> */}
      <Container
        id="success-story"
        className="base-container featureCarousal"
        maxWidth="unset"
        style={{ backgroundColor: "#FFF8EE", scrollMargin: "2em" }}
      >
        <img
          src="/images/dot_design.png"
          style={{ position: "absolute", right: 0 }}
          alt="dot design"
        />
        <div>
          <ComponentHeader
            headingText="Success stories from our Matrimony service"
            borderWidth="8em"
          />
        </div>

        <AliceCarousel
          disableButtonsControls={false}
          dotsDisabled={true}
          responsive={featureResponsive}
          mouseTrackingEnabled
          renderPrevButton={false}
          renderNextButton={false}
          autoPlay={true}
          autoPlayInterval={5000}
          autoPlayDirection="ltr"
          stopAutoPlayOnHover={true}
          stagePadding={
            window.innerWidth < 1000 ? mobilestagePadding : desktopstagePadding
          }
          fadeOutAnimation={false}
        >
          <Card className="love_card_gradient imgslider">
            <CardHeader
              style={{ paddingBottom: 0, color: "#ffffff !important" }}
              // avatar={
              //   <Avatar
              //     sx={{ border: "2px solid white", width: 60, height: 60 }}
              //   >
              //     <img
              //       src="https://media.weddingz.in/images/0ee33e6709ff04b87409396a441d4fae/15-couple-portrait-ideas-for-getting-framed-4.jpg"
              //       style={{ objectFit: "fill", height: "100%", width: "100%" }}
              //       alt="Profile Pic"
              //     />
              //   </Avatar>
              // }
              // TODO have to add class map here
              title="Sathyasimhan & Rama"
              subheader="Married, 31st Jan 2024"
            />
            <CardContent sx={{ paddingTop: "1" }}>
              <div>
                <Typography
                  color="red"
                  fontWeight={900}
                  fontSize={20}
                  // sx={{ paddingLeft: "2em" }}
                >
                  "Great initiative by JSPN"
                </Typography>
                <Typography
                  color="black"
                  fontWeight={400}
                  className="responsivetext"
                >
                  ಇಲ್ಲಿರುವ ಎಲ್ಲ profile ಗಳು verified ಆಗಿವೆ. ಸರಳವಾಗಿದೆ & ಎಲ್ಲರಿಗೂ
                  ಉಪಯುಕ್ತವಾದ ಆ್ಯಪ್ ಇದಾಗಿದೆ.
                </Typography>
              </div>
            </CardContent>
          </Card>
          <Card className="love_card_gradient imgslider">
            <CardHeader
              style={{ paddingBottom: 0, color: "#ffffff !important" }}
              // avatar={
              //   <Avatar
              //     sx={{ border: "2px solid white", width: 60, height: 60 }}
              //   >
              //     <img
              //       src="https://www.whatspaper.com/wp-content/uploads/2021/01/hd-lion-walpaper-whatspaper.jpg"
              //       style={{ objectFit: "fill", height: "100%", width: "100%" }}
              //       alt="Profile Pic"
              //     />
              //   </Avatar>
              // }
              // TODO have to add class map here
              title="Pratyusha & Venkat"
              subheader="Marriage on Dec 20th 2024"
            />
            <CardContent sx={{ paddingTop: "1" }}>
              <div>
                <Typography color="red" fontWeight={900} fontSize={20} sx={{}}>
                  "Great service"
                </Typography>
                <Typography
                  color="black"
                  fontWeight={400}
                  className="responsivetext"
                >
                  With HVG anugraha we found a suitable match for my daughter in
                  just couple of months. I recommend it to everyone from Madhwa
                  community. Shri Satyatma Teertha Gurubhyo Namaha.
                </Typography>
              </div>
            </CardContent>
          </Card>
          <Card className="love_card_gradient imgslider">
            <CardHeader
              style={{ paddingBottom: 0, color: "#ffffff !important" }}
              // avatar={
              //   <Avatar
              //     sx={{ border: "2px solid white", width: 60, height: 60 }}
              //   >
              //     <img
              //       src="https://wallpaper.dog/large/986841.jpg"
              //       style={{ objectFit: "fill", height: "100%", width: "100%" }}
              //       alt="Profile Pic"
              //     />
              //   </Avatar>
              // }
              // TODO have to add class map here
              title="Bhargavi and Gururaj"
              subheader="Marriage on, 16th Feb 2025"
            />
            <CardContent sx={{ paddingTop: "1" }}>
              <div style={{ paddingLeft: "" }}>
                <Typography
                  color="red"
                  fontWeight={900}
                  fontSize={20}
                  sx={{ paddingLeft: "" }}
                >
                  "Thanks to JSPN"
                </Typography>
                <Typography
                  color="black"
                  fontWeight={400}
                  className="responsivetext"
                >
                  I could find a partner who can match the liberal traditional
                  values of my family. Happy to see a matrimony site exclusively
                  for Madhwa Community.
                </Typography>
              </div>
            </CardContent>
          </Card>
        </AliceCarousel>
      </Container>
      {openSuccessModal && (
        <RegistrationSuccessModal
          open={openSuccessModal}
          onClose={() => {
            setSuccessModal(false);
          }}
        />
      )}
      {!!openForgetPwModal && (
        <ForgetPasswordModal
          open={openForgetPwModal}
          data={{ validateViaOTP: true }}
          onClose={() => {
            toggleOpenForgetPwModal(false);
          }}
          next={() => {
            toggleOpenForgetPwModal(false);
          }}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
